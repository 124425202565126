import { useQuery } from '@tanstack/react-query'
import { SERVICES } from 'common/api'
import { TRAVELPORT_SUPPLIER_CODE } from 'common/constants'
import { Permission, UserRole } from 'common/enums'
import { pickUniqueSipplierCodes } from 'common/helpers'
import { fetchUserDetails } from 'common/services/auth.service'
import { fetchCdnToken } from 'common/services/cdn.service'
import { AuthUser } from 'common/types'
import { envConfig } from 'config/config'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

export type AuthContextType = {
  user: AuthUser | null
  TVPAuthorization: string | null
  logOut: () => void
  hasPermissionTo: (permission: Permission) => boolean
  isAdminUser: boolean
  isTravelportUser: boolean
  userSupplierCodes: string[]
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  TVPAuthorization: null,
  logOut: () => ({}),
  hasPermissionTo: () => false,
  isAdminUser: false,
  isTravelportUser: false,
  userSupplierCodes: [],
})

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}

export const AuthContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [TVPAuthorization, setTVPAuthorization] = useState<string | null>(null)
  const navigate = useNavigate()

  const { data: user = null } = useQuery({
    queryKey: ['getUser'],
    queryFn: () =>
      fetchUserDetails().catch(() => {
        console.log(
          'Failed to retrieve user-details. Falling back to session invalid page...',
        )
        throw new Error('Invalid Auth Details: 401')
      }),
  })

  const logOut = () => {
    localStorage.setItem('selectedCarrierCode', '')
    window.location.assign(
      `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/logout`,
    )
  }

  useEffect(() => {
    if (user && !TVPAuthorization) {
      fetchCdnToken()
        .then((token) => {
          sessionStorage.setItem('TVPAuthorization', token)
          setTVPAuthorization(token)
        })
        .catch(() => {
          console.log(
            'Failed to retrieve CDN token. Falling back to invalid session page...',
          )
          throw new Error('Invalid Auth Details: 401')
        })
      setTimeout(
        () => setTVPAuthorization(null),
        30 * 1000 * 60, // 30 min
      )
    }
  }, [TVPAuthorization, user, navigate])

  const hasPermissionTo = useCallback(
    (permission: Permission): boolean => {
      if (!user) {
        return false
      }

      return user.permissions.includes(permission)
    },
    [user],
  )

  const isAdminUser = useMemo(() => {
    if (!user) {
      return false
    }

    return user.roles.includes(UserRole.MMP_ADMIN)
  }, [user])

  const userSupplierCodes = useMemo(() => {
    if (!user) {
      return []
    }

    const { supplierCodes } = user
    return pickUniqueSipplierCodes(supplierCodes)
  }, [user])

  const isTravelportUser = useMemo(() => {
    if (!user) {
      return false
    }

    return userSupplierCodes.includes(TRAVELPORT_SUPPLIER_CODE)
  }, [user, userSupplierCodes])

  return (
    <AuthContext.Provider
      value={{
        user,
        TVPAuthorization,
        logOut,
        hasPermissionTo,
        isAdminUser,
        isTravelportUser,
        userSupplierCodes,
      }}
    >
      {user && children}
    </AuthContext.Provider>
  )
}
